import React from 'react';

function Product({ name, description, price, image }) {
  return (
    <div className="card" style={{ width: '18rem' }}>
      <img src={image} className="card-img-top" alt={name} />
      <div className="card-body">
        <h5 className="card-title">{name}</h5>
        <p className="card-text">{description}</p>
        <p className="card-text">${price}</p>
        <a href="#" className="btn btn-primary">Buy Now</a>
      </div>
    </div>
  );
}

export default Product;
