import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { useTheme } from "next-themes";
import { Link } from "react-router-dom";
import {
  fetchSimpleIcons,
  renderSimpleIcon,
} from "react-icon-cloud";
import IconCloud from "../components/magicui/IconCloud";
import { ScrollTop } from 'primereact/scrolltop';
import "./Home.css";

const technologies = [
  { slug: "typescript", name: "TypeScript", category: "frontend" },
  { slug: "javascript", name: "JavaScript", category: "frontend" },
  { slug: "dart", name: "Dart", category: "frontend" },
  { slug: "react", name: "React", category: "frontend" },
  { slug: "flutter", name: "Flutter", category: "frontend" },
  { slug: "java", name: "Java", category: "backend" },
  { slug: "springboot", name: "Spring Boot", category: "backend" },
  { slug: "nodedotjs", name: "Node.js", category: "backend" },
  { slug: "express", name: "Express", category: "backend" },
  { slug: "nextdotjs", name: "Next.js", category: "backend" },
  { slug: "prisma", name: "Prisma", category: "backend" },
  { slug: "postgresql", name: "PostgreSQL", category: "database" },
  { slug: "mysql", name: "MySql", category: "database" },
  { slug: "oracle", name: "Oracle", category: "database" },
  { slug: "mongodb", name: "MongoDB", category: "database" },
  { slug: "firebase", name: "Firebase", category: "database" },
  { slug: "docker", name: "Docker", category: "devops" },
  { slug: "nginx", name: "Nginx", category: "devops" },
  { slug: "kubernetes", name: "Kubernetes", category: "devops" },
  { slug: "jenkins", name: "Jenkins", category: "devops" },
  { slug: "git", name: "Git", category: "tools" },
  { slug: "github", name: "GitHub", category: "tools" },
  { slug: "jira", name: "Jira", category: "tools" },
  { slug: "visualstudiocode", name: "VS Code", category: "tools" },
  { slug: "figma", name: "Figma", category: "design" },
  { slug: "adobephotoshop", name: "Figma", category: "design" },
  { slug: "adobeillustrator", name: "Illustrator", category: "design" }, 
  { slug: "canva", name: "Canva", category: "design" }, 
  { slug: "blender", name: "Blender", category: "design" },
];

const tabs = ["frontend", "backend", "database", "devops", "tools", "design"];

export const cloudProps = {
  containerProps: {
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingTop: 40,
    },
  },
  options: {
    reverse: true,
    depth: 1,
    wheelZoom: false,
    imageScale: 2,
    activeCursor: "default",
    tooltip: "native",
    initial: [0.1, -0.1],
    clickToFront: 500,
    tooltipDelay: 0,
    outlineColour: "#0000",
    maxSpeed: 0.04,
    minSpeed: 0.02,
  },
};

export const renderCustomIcon = (icon, theme) => {
  const bgHex = theme === "light" ? "#f3f2ef" : "#080510";
  const fallbackHex = theme === "light" ? "#6e6e73" : "#ffffff";
  const minContrastRatio = theme === "dark" ? 2 : 1.2;

  return renderSimpleIcon({
    icon,
    bgHex,
    fallbackHex,
    minContrastRatio,
    size: 42,
    aProps: {
      href: undefined,
      target: undefined,
      rel: undefined,
      onClick: (e) => e.preventDefault(),
    },
  });
};

export default function Home() {
  const [selectedTab, setSelectedTab] = useState("frontend");
  const { theme } = useTheme();
  const [data, setData] = useState(null);

  useEffect(() => {
    const slugs = technologies.map((tech) => tech.slug);
    fetchSimpleIcons({ slugs }).then(setData);
  }, []);

  const filteredTechnologies = technologies.filter(
    (tech) => tech.category === selectedTab
  );

  useEffect(() => {
    gsap.fromTo(
      ".tech-list-item",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 0.6 }
    );
  
  }, [selectedTab,filteredTechnologies]);


  const renderedIcons = data
    ? filteredTechnologies.map((tech) => {
        const icon = data.simpleIcons[tech.slug];
        return (
          <div key={tech.slug} className="tech-list-item">
            {renderCustomIcon(icon, theme || "light")}
            <p>{tech.name}</p>
          </div>
        );
      })
    : null;

  return (
    <div>
      <div className="hero">
        <div className="hero-content">
          <h1 className="hero-text">Welcome to Techveda</h1>
          <p>Innovative Solutions for Modern Businesses</p>
          <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="services section">
        <h2>Our Services</h2>
        <div className="card-container">
          <div className="card">
            <h3>Web Development</h3>
            <p>
              We create stunning and responsive websites that meet your business
              needs.
            </p>
          </div>
          <div className="card">
            <h3>Mobile Development</h3>
            <p>
              Our mobile solutions are tailored to provide the best user
              experience on any device.
            </p>
          </div>
          <div className="card">
            <h3>Cloud Solutions</h3>
            <p>
              Leverage the power of the cloud with our robust and scalable
              solutions.
            </p>
          </div>
        </div>
      </div>

      <div className="features section">
        <h2>Why Choose Us</h2>
        <div className="card-container">
          <div className="card">
            <h3>Expert Team</h3>
            <p>
              Our team of experts ensures that you get the best solutions for
              your business.
            </p>
          </div>
          <div className="card">
            <h3>Customer Support</h3>
            <p>
              We offer 24/7 customer support to assist you with any issues or
              queries.
            </p>
          </div>
          <div className="card">
            <h3>Quality Assurance</h3>
            <p>
              We deliver high-quality products that meet industry standards and
              exceed your expectations.
            </p>
          </div>
        </div>
      </div>

      <div className="technology section">
        <h2>Technologies We Use</h2>
        <div className="tech-container">
          <div className="tech-cloud">
            <IconCloud iconSlugs={technologies.map((tech) => tech.slug)} />
          </div>
          <div className="tabs-contaiar">
            <div className="tabs">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`tab-button ${selectedTab === tab ? "active" : ""}`}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="tech-list">
              {renderedIcons}
            </div>
          </div>
        </div>
      </div>

      <div className="contact section">
        <h2>Contact Us</h2>
        <p>Have any questions? Feel free to reach out to us!</p>
        <Link className="btn" to="/contact">
          Get in Touch
        </Link>
      </div>
      <ScrollTop style={{background:"#0590afa3",padding:"20px",color:"white",borderRadius:"50%",backdropFilter:"blur(5px)"}} />
    </div>
  );
}
