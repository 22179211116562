import React from 'react';

function About() {
  return (
    <div className="container my-5">
      <h2>About Techveda</h2>
      <p>Techveda is a leading provider of innovative software solutions. Our team of experts specializes in various cutting-edge technologies to deliver top-notch services to our clients.</p>
      <h3>Our Team</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <img src="/img/MihirBhuva.jpeg" className="card-img-top" alt="Partner 1" />
            <div className="card-body">
              <h5 className="card-title">Mihir Bhuva</h5>
              <p className="card-text">CEO & Founder</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <img src="/img/PrinsVekariya.jpeg" className="card-img-top" alt="Partner 2" />
            <div className="card-body">
              <h5 className="card-title">Prince Vekariya</h5>
              <p className="card-text">Co-CEO & Co-Founder</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
